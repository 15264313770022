<template>
  <el-dialog title="服务详情" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form :model="serviceDetailData" label-width="100px" :disabled="true" v-if="serviceDetailData">
      <el-form-item label="服务名称">
        <el-input v-model="serviceDetailData.title"></el-input>
      </el-form-item>
      <el-form-item label="机器类型">
        <el-input v-model="serviceDetailData.subTypeName"></el-input>
      </el-form-item>
      <el-form-item label="使用类型">
        <el-input v-model="serviceDetailData.useTypeName"></el-input>
      </el-form-item>
      <el-form-item label="地址"
        >{{ serviceDetailData.province }}{{ serviceDetailData.city }}{{ serviceDetailData.county }}{{ serviceDetailData.village }}</el-form-item
      >
      <el-form-item label="详细地址">
        <el-input v-model="serviceDetailData.address"></el-input>
      </el-form-item>
      <el-form-item label="主题图片">
        <el-image style="width: 100px; height: 100px" :src="serviceDetailData.titleImgUrl" fit="fit"></el-image>
      </el-form-item>
      <el-form-item label="服务方">
        <el-input v-model="serviceDetailData.serviceOrgName"></el-input>
      </el-form-item>
      <el-form-item label="联系人">
        <el-input v-model="serviceDetailData.contractName"></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input v-model="serviceDetailData.contractPhone"></el-input>
      </el-form-item>
      <quill-editor :disabled="true" class="editor" :options="editorOption" :content="serviceDetailData.content" />
    </el-form>
  </el-dialog>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';
import { getRequest } from '@/api/api.js';
export default {
  props: {
    dialogVisible: false,
    id: ''
  },
  watch: {
    id(val) {
      if (val) {
        this.getServiceDetailData();
      }
    }
  },
  components: {
    quillEditor
  },
  data() {
    return {
      // 富文本编辑器配置
      hideUploadEdit: false,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            ['blockquote', 'code-block'], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            // [{ font: ['songti'] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ['clean'], // 清除文本格式
            ['image', 'video'] // 链接、图片、视频
          ]
        },
        placeholder: '请输入正文'
      },
      serviceDetailData: null
    };
  },
  created() {},
  destroyed() {},
  mounted() {},

  methods: {
    closeMe() {
      this.$emit('update:dialogVisible', false);
    },
    getServiceDetailData() {
      getRequest('/api/operate/govserv/uav/apply/service/view', { id: this.id })
        .then((res) => {
          this.serviceDetailData = res;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>
<style scoped lang="scss">
.editor {
  width: 100%;
  height: 200px;
  overflow: scroll;
}
</style>
