<template>
  <el-dialog title="详情" :visible="showRecordsFlag" width="60%" @close="closeDg" destroy-on-close :close-on-click-modal="false">
    <el-table v-if="records.length" ref="multipleTable" :data="records" stripe>
      <el-table-column prop="machineName" label="类型"> </el-table-column>
      <el-table-column prop="code" label="内部编码"> </el-table-column>
      <el-table-column prop="factoryName" label="厂商名称"> </el-table-column>
      <el-table-column prop="machineBelongTypeName" label="归属"> </el-table-column>
      <el-table-column prop="workArea" label="亩数（亩）"> </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
export default {
  name: 'machineRecords',
  props: {
    records: [],
    showRecordsFlag: false,
    resourceTypeDic: {}
  },
  data() {
    return {};
  },
  methods: {
    closeDg() {
      this.$emit('update:showRecordsFlag', false);
    },
    getName(key) {
      return this.resourceTypeDic[key];
    },
    getAbsAmount(val) {
      return Math.abs(val);
    }
  }
};
</script>
<style lang="scss" scoped></style>
