<template>
  <div>
    <el-dialog title="申请详情" :visible="dialogVisible" width="50%" @close="closeDg" destroy-on-close :close-on-click-modal="false">
      <el-form v-if="applyDetailData" label-width="120px">
        <el-form-item label="服务:">{{ (applyDetailData.serviceInfo && applyDetailData.serviceInfo.title) || '-' }}</el-form-item>
        <el-form-item label="无人机类型:">{{ applyDetailData.subTypeName || '-' }}</el-form-item>
        <el-form-item label="机型备注:">{{ applyDetailData.strAttr1 || '-' }}</el-form-item>
        <el-form-item label="用途类型:"> {{ applyDetailData.useTypeName || '-' }} </el-form-item>
        <el-form-item label="申请人:"> {{ applyDetailData.applyName || '-' }} </el-form-item>
        <el-form-item label="联系电话:"> {{ applyDetailData.applyPhone || '-' }} </el-form-item>
        <el-form-item label="关联地块:"> {{ applyDetailData.landName || '-' }} </el-form-item>
        <el-form-item label="面积:"> {{ formatPlantArea(applyDetailData.doubleAttr1) }}亩</el-form-item>
        <el-form-item label="地址:"
          >{{ applyDetailData.province }}{{ applyDetailData.city }}{{ applyDetailData.county }}{{ applyDetailData.township
          }}{{ applyDetailData.village }}</el-form-item
        >
        <el-form-item label="详细地址:"> {{ applyDetailData.address || '-' }} </el-form-item>
        <el-form-item label="备注:"> {{ applyDetailData.descriptions || '-' }} </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import * as utils from '@/utils/utils.js';
import { getRequest } from '@/api/api';
export default {
  props: {
    dialogVisible: false,
    id: ''
  },
  data() {
    return {
      applyDetailData: null
    };
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val) {
          this.getDetailData();
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    getDetailData() {
      getRequest('/api/operate/govserv/uav/work/record/view', { id: this.id })
        .then((res) => {
          this.applyDetailData = res;
        })
        .catch((err) => {});
    },
    closeDg() {
      this.$emit('update:dialogVisible', false);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row-dia {
  display: flex;
}

.flex-center {
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
}
.select-means-wrap {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 15px;
  .means-item {
    display: flex;
    align-items: center;
    color: #313746;
    background: #f7f8fa;
    margin-bottom: 20px;
    border-radius: 8px;
    flex-wrap: wrap;
    .selected-means-del {
      color: #409eff;
      cursor: pointer;
      font-size: 14px;
      width: 60px;
      text-align: center;
    }
    .left {
      flex: 1;
    }
    .row1,
    .row2 {
      width: 50%;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: space-between;
      padding: 0 20px;
    }
  }
  // span {
  //   width: 50%;
  // }
}
.el-pagination {
  text-align: center;
  margin-top: 10px;
}
</style>
