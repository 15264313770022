<template>
  <div>
    <el-dialog title="接受请求" :visible="dialogVisible" width="60%" @close="closeDg" destroy-on-close :close-on-click-modal="false">
      <el-form ref="ruleForm" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="农事节点" prop="farmingTypeId">
          <div class="flex-row-dia">
            <!-- <el-select v-model="form.farmingStage" placeholder="请选择农事节点" @change="stageChange">
              <el-option v-for="item in farmingTypesData" :key="item.stageCode" :label="item.stageName" :value="item.stageCode"> </el-option>
            </el-select> -->
            <el-select v-model="form.farmingTypeId" placeholder="请选择农事名称">
              <el-option v-for="item in farmingTypeNames" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="地块名称" prop="landName">
          <el-input style="width: 200px; margin-right: 4px" v-model="form.landName" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item label="种植面积" prop="plantArea">
          <el-input placeholder="请输入内容" style="width: 200px; margin-right: 4px" v-model="form.plantArea" :disabled="true"> </el-input>亩
        </el-form-item>
        <el-form-item label="作业面积" prop="completeArea">
          <el-input
            type="number"
            placeholder="请输入内容"
            style="width: 200px; margin-right: 4px"
            @input="completeAreaInput"
            @blur="completeAreaBlur"
            v-model="form.completeArea"
          >
          </el-input
          >亩
        </el-form-item>
        <el-form-item label="参与人" prop="executorName">
          <el-input placeholder="请输入内容" style="width: 200px; margin-right: 4px" v-model="form.executorName"> </el-input>
        </el-form-item>
        <el-form-item label="农事时间" prop="farmDate">
          <el-date-picker v-model="farmDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" autosize v-model="form.descriptions"></el-input>
        </el-form-item>
        <el-form-item label="上传照片">
          <el-upload
            :class="{ hideUpload: hideUploadEdit }"
            action=""
            ref="upload"
            :file-list="fileList"
            :limit="1"
            list-type="picture-card"
            accept="image/jpg,image/jpeg,image/png"
            :auto-upload="false"
            :on-change="fileChange"
            :on-remove="fileChange"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="添加农资">
          <el-button @click="addMeans()">+</el-button>
        </el-form-item>
        <el-form-item label="">
          <div class="select-means-wrap">
            <template v-for="item in meansList">
              <div v-if="item.selected" class="means-item" :key="item.id">
                <div class="left">
                  <div class="row">
                    <span>农资：{{ item.name || '-' }}</span>
                    <span>规格：{{ item.countPerUnit }}{{ unitSpecs[item.unitSpecs] }}/{{ unitMeasurement[item.unitOfMeasurement] }}</span>
                  </div>
                  <div class="row">
                    <span>数量：{{ item.meansNum }}{{ unitMeasurement[item.unitOfMeasurement] }}</span>
                    <span>总量：{{ item.totalMeansVal }}{{ unitSpecs[item.unitSpecs] }}</span>
                  </div>
                </div>
                <div class="selected-means-del" @click="meansSelectChange(false, item)">删除</div>
              </div>
            </template>
          </div>
        </el-form-item>
        <el-form-item label="添加无人机">
          <el-button @click="addUav()">+</el-button>
        </el-form-item>
        <el-form-item label="">
          <div class="select-means-wrap">
            <el-table-column prop="machineTypeName" label=""> </el-table-column>
            <el-table-column prop="code" label=""> </el-table-column>
            <el-table-column prop="factoryName" label=""> </el-table-column>
            <el-table-column prop="machineBelongTypeName" label=""> </el-table-column>

            <template v-for="item in uavList">
              <div v-if="item.selected" class="means-item" :key="item.id">
                <div class="left">
                  <div class="row">
                    <span>农机类型：{{ item.machineTypeName || '-' }}</span>
                    <span>内部编码：{{ item.code || '-' }}</span>
                  </div>
                  <div class="row">
                    <span>厂商名称：{{ item.factoryName || '-' }}</span>
                    <span>农机归属：{{ item.machineBelongTypeName || '-' }}</span>
                  </div>
                  <div class="row">
                    <span>亩数（亩）：{{ item.uavAreaNum || '-' }}</span>
                  </div>
                </div>
                <div class="selected-means-del" @click="uavSelectChange(false, item)">删除</div>
              </div>
            </template>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即接受</el-button>
          <el-button @click="closeDg()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="农资列表" :visible="meansDgVisible" width="60%" @close="closeMeansDg" destroy-on-close :close-on-click-modal="false">
      <el-table v-if="meansList.length" ref="multipleTable" :data="meansList" stripe>
        <el-table-column label="选择状态" width="100">
          <template slot-scope="scope">
            <el-checkbox
              @change="
                (val) => {
                  meansSelectChange(val, scope.row);
                }
              "
              v-model="scope.row.selected"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="农资"> </el-table-column>
        <el-table-column prop="agricultureResourceTypeName" label="类型"> </el-table-column>
        <el-table-column label="规格">
          <template slot-scope="scope">
            {{ scope.row.countPerUnit }}{{ unitSpecs[scope.row.unitSpecs] }}/{{ unitMeasurement[scope.row.unitOfMeasurement] }}
          </template>
        </el-table-column>
        <el-table-column label="库存数">
          <template slot-scope="scope">
            {{ scope.row.availableAmount }}
          </template>
        </el-table-column>
        <el-table-column label="数量">
          <template slot-scope="scope">
            <el-input
              @change="
                (val) => {
                  numInputChange(val, scope.row);
                }
              "
              type="number"
              min="1"
              v-model="scope.row.meansNum"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="总量">
          <template slot-scope="scope"> {{ scope.row.totalMeansVal }}{{ unitSpecs[scope.row.unitSpecs] }} </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeMeansDg">取 消</el-button>
        <el-button type="primary" @click="submitMeansSelect">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="无人机列表" :visible="uavDgVisible" width="60%" @close="closeUavDg" destroy-on-close :close-on-click-modal="false">
      <el-table v-if="uavList.length" ref="multipleTable" :data="uavList" stripe>
        <el-table-column label="选择状态" width="100">
          <template slot-scope="scope">
            <el-checkbox
              @change="
                (val) => {
                  uavSelectChange(val, scope.row);
                }
              "
              v-model="scope.row.selected"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="machineTypeName" label="农机类型"> </el-table-column>
        <el-table-column prop="code" label="内部编码"> </el-table-column>
        <el-table-column prop="factoryName" label="厂商名称"> </el-table-column>
        <el-table-column prop="machineBelongTypeName" label="农机归属"> </el-table-column>

        <el-table-column label="亩数（亩）">
          <template slot-scope="scope">
            <el-input
              type="number"
              placeholder="亩数"
              @change="
                (val) => {
                  uavInputChange(val, scope.row);
                }
              "
              v-model="scope.row.uavAreaNum"
            >
            </el-input>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeUavDg">取 消</el-button>
        <el-button type="primary" @click="submitUavSelect">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { productionReport, receiveUavApply, farmingTypes, getRequest } from '@/api/api.js';

// request.get('/api/operate/planting/land/farm/view', { params });
export default {
  props: {
    dialogVisible: false,
    applyData: null,
    isEdit: true,
    departmentList: [],
    plantLands: []
  },
  components: {},
  computed: {},
  data() {
    return {
      hideUploadEdit: false,
      rules: {
        farmingTypeId: [{ required: true, message: '请选择农事名称', trigger: 'change' }],
        landName: [{ required: true, message: '请输入地块名称', trigger: 'blur' }],
        plantArea: [{ required: true, message: '请输入种植面积', trigger: 'blur' }],
        completeArea: [{ required: true, message: '请输入作业面积', trigger: 'blur' }],
        executorName: [{ required: true, message: '请输入参与人', trigger: 'blur' }],
        farmDate: [
          {
            validator: this.validateFarmDate,
            required: true
          }
        ]
      },
      form: {
        farmingTypeId: '',
        landName: '',
        plantArea: 0,
        completeArea: '',
        executorName: '',
        descriptions: ''
      },
      fileList: [],
      farmDate: '',
      meansPageInfo: {
        currentPage: 1,
        pageSize: 99999,
        totalPage: 0
      },
      meansList: [],
      selectIds: [],
      meansDgVisible: false,
      uavDgVisible: false,
      uavList: [],
      unitSpecs: {},
      unitMeasurement: {},
      selectMeans: [],
      farmingTypeNames: [
        {
          id: 51,
          name: '病虫防治'
        },
        {
          id: 48,
          name: '追肥'
        }
      ],
      startDate: '',
      farmingTypesData: []
    };
  },
  created() {
    this.getDicList();
    this.getFarmingTypes();
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.hideUploadEdit = false;
        let { landId, landName, plantArea, doubleAttr1 } = this.applyData;
        this.form = {
          farmingTypeId: '',
          landId: landId,
          landName: landName,
          plantArea: plantArea,
          completeArea: doubleAttr1 || plantArea,
          descriptions: ''
        };
        this.completeAreaInput();
      } else {
        this.hideUploadEdit = false;
        this.form = {
          farmingTypeId: '',
          landId: '',
          landName: '',
          plantArea: '',
          completeArea: '',
          descriptions: ''
        };

        this.fileList = [];
        this.farmDate = [];
        this.meansList.forEach((item) => {
          item.selected = false;
          item.meansNum = 1;
          item.totalMeansVal = item.countPerUnit;
        });
        this.uavList.forEach((item) => {
          item.selected = false;
          item.workArea = 0;
        });
      }
    }
  },
  methods: {
    completeAreaInput() {
      let { plantArea, completeArea } = this.form;
      if (completeArea > plantArea * 1.05) {
        completeArea = plantArea * 1.05;
      }
      this.form.completeArea = completeArea;
    },
    completeAreaBlur() {
      if (this.form.completeArea) {
        this.form.completeArea = Number(this.form.completeArea).toFixed(2);
      }
    },
    getFarmingTypes() {
      farmingTypes({})
        .then((res) => {
          this.farmingTypesData = res;
        })
        .catch((error) => {});
    },
    // stageChange(val) {
    //   this.farmingTypesData.map((item) => {
    //     if (item.stageCode == val) {
    //       this.farmingTypeNames = item.farmingTypes;
    //     }
    //   });
    //   this.form.farmingTypeId = '';
    // },
    landChange(val) {
      this.form.landId = val;
      this.getArea();
    },
    getArea() {
      this.plantLands.forEach((item) => {
        if (item.id == this.form.landId) {
          this.form.completeArea = item.area;
          return;
        }
      });
    },
    fileChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
      this.fileList = fileList;
    },
    editMeansList(rows) {
      let list = [];
      rows.forEach((item) => {
        if (item.availableAmount && item.availableAmount > 0) {
          item.meansNum = 1;
          item.totalMeansVal = item.meansNum * item.countPerUnit;
          list.push(item);
        }
      });
      return list;
    },
    editUavList(rows) {
      let list = [];
      rows.forEach((item) => {
        item.uavAreaNum = 1;
        list.push(item);
      });
      return list;
    },
    addMeans() {
      if (this.meansList.length) {
        this.tempMeansList = JSON.parse(JSON.stringify(this.meansList));
        this.meansDgVisible = true;
        return;
      }
      productionReport({
        pageNum: this.meansPageInfo.currentPage,
        pageSize: this.meansPageInfo.pageSize
      })
        .then((res) => {
          this.meansList = this.editMeansList(res.rows);
          this.tempMeansList = JSON.parse(JSON.stringify(this.meansList));
          this.meansDgVisible = true;
          this.meansPageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    addUav() {
      if (this.uavList.length) {
        this.tempUavList = JSON.parse(JSON.stringify(this.uavList));
        this.uavDgVisible = true;
        return;
      }
      getRequest('/api/operate/govserv/uav/apply/machine/page', { pageSize: 999, pageNum: 1 })
        .then((res) => {
          this.uavList = this.editUavList(res.rows);
          this.tempUavList = JSON.parse(JSON.stringify(this.uavList));
          this.uavDgVisible = true;
        })
        .catch((error) => {});
    },
    async getDicList() {
      let res = await this.$store.dispatch('getDicAll');
      this.unitSpecs = res['unit.specs'];
      this.unitMeasurement = res['unit.of.measurement'];
    },
    numInputChange(val, row) {
      if (!val || val <= 0) {
        val = 1;
      } else if (val > row.availableAmount) {
        val = row.availableAmount;
      }
      this.meansList.forEach((item) => {
        if (item.id == row.id) {
          item.meansNum = parseInt(val);
          item.totalMeansVal = parseInt(val) * item.countPerUnit;
        }
      });
    },
    uavInputChange(val, row) {
      if (!val || val <= 0) {
        val = 1;
      }
      this.uavList.forEach((item) => {
        if (item.id == row.id) {
          item.uavAreaNum = parseFloat(val);
        }
      });
    },
    meansSelectChange(val, row) {
      this.meansList.forEach((item) => {
        if (item.id == row.id) {
          item.selected = val;
        }
      });
    },
    uavSelectChange(val, row) {
      this.uavList.forEach((item) => {
        if (item.id == row.id) {
          item.selected = val;
        }
      });
    },
    submitMeansSelect() {
      this.tempMeansList = JSON.parse(JSON.stringify(this.meansList));
      this.meansDgVisible = false;
    },
    closeMeansDg() {
      this.meansList = JSON.parse(JSON.stringify(this.tempMeansList));
      this.meansDgVisible = false;
    },
    submitUavSelect() {
      this.tempUavList = JSON.parse(JSON.stringify(this.uavList));
      this.uavDgVisible = false;
    },
    closeUavDg() {
      this.uavList = JSON.parse(JSON.stringify(this.tempUavList));
      this.uavDgVisible = false;
    },
    getRowKey(row) {
      return row.id;
    },
    getSelectIds() {
      let useStocks = [];
      this.meansList.forEach((item) => {
        let selObj = {};
        if (item.selected) {
          selObj['productionId'] = item.id;
          selObj['amount'] = item.meansNum;
          useStocks.push(selObj);
        }
      });
      return useStocks;
    },
    getSelectUavIds() {
      let useMachines = [];
      this.uavList.forEach((item) => {
        let selObj = {};
        if (item.selected) {
          selObj['machineId'] = item.id;
          selObj['workArea'] = item.uavAreaNum;
          useMachines.push(selObj);
        }
      });
      return useMachines;
    },
    validateFarmDate(rule, value, callback) {
      this.farmDate && this.farmDate.length ? callback() : callback(new Error(`请选择农事时间`));
    },
    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let useStocks = this.getSelectIds();
          let useMachines = this.getSelectUavIds();
          if (useMachines && useMachines.length) {
            let params = { ...this.form };
            params.uavTaskId = this.applyData.id;
            if (this.farmDate.length) {
              params.startDate = this.farmDate[0] ? dayjs(this.farmDate[0]).format('YYYY-MM-DD') : '';
              params.endDate = this.farmDate[1] ? dayjs(this.farmDate[1]).format('YYYY-MM-DD') : '';
            }
            params.useStocks = useStocks;
            params.useMachines = useMachines;
            let formData = new FormData();
            if (this.fileList && this.fileList.length && this.fileList[0].raw) {
              formData.append('file', this.fileList[0].raw);
            }
            if (this.isLoading) {
              return;
            }
            this.isLoading = true;
            formData.append('requestJson', JSON.stringify({ ...params }));
            receiveUavApply(formData)
              .then((res) => {
                this.$message({
                  message: '已接受',
                  type: 'success'
                });
                this.closeDg();
                this.updateList();
                this.isLoading = false;
              })
              .catch((error) => {
                this.isLoading = false;
              });
          } else {
            this.$message({
              message: '请添加无人机',
              type: 'warning'
            });
          }
        }
      });
    },
    closeDg() {
      this.$emit('update:dialogVisible', false);
    },
    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row-dia {
  display: flex;
}

.flex-center {
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
}
.select-means-wrap {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 15px;
  .means-item {
    display: flex;
    align-items: center;
    color: #313746;
    background: #f7f8fa;
    margin-bottom: 20px;
    border-radius: 8px;
    flex-wrap: wrap;
    .selected-means-del {
      color: #409eff;
      cursor: pointer;
      font-size: 14px;
      width: 60px;
      text-align: center;
    }
    .left {
      flex: 1;
    }
    .row {
      width: 50%;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: space-between;
      padding: 0 20px;
    }
  }
  // span {
  //   width: 50%;
  // }
}
.el-pagination {
  text-align: center;
  margin-top: 10px;
}
</style>
